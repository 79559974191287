.ChatHeader {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  padding: 8px 14px;
  border-bottom: 1px solid var(--qt-divider);
  background: var(--qt-gray1);
  button {
    font-size: 13px;
  }
  .TicketIcon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MainContainer {
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .NameContainer {
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      font-size: 13px;
      .Badge {
      }
      .Name {
        flex: 1 1 auto;
        width: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .TicketStatus {
      display: flex;
      margin-right: 6px;
      align-items: center;
    }
  }
  &.Closed {
    color: var(--qt-gray7);
  }
}

.DropDownMenu {
  padding: 4px;
}

.DropDownButton {
  padding: 0;
  height: 36px;
  font-size: 14px;
  border: none;
  display: flex;
  align-items: center;
  gap: 6px;
  svg {
    margin: 0;
    min-width: 20px;
    min-height: 20px;
  }
}
