@import '../../scss/breakpoints';

.ImageUploadWrap {
  display: flex;
  position: relative;
  cursor: pointer;

  min-width: 6.25rem;
  max-width: 6.25rem;
  height: 6.25rem;

  .ResetAvatar {
    display: none;
    position: absolute;
    right: -0.25rem;
    top: -0.125rem;
    line-height: 100%;
    color:var(--bs-secondary);
    z-index: 2;

    &:hover{
      color:var(--bs-danger);
    }
  }
  &:hover{
    border:0;

    .AvatarWrap {
      object-fit: cover;
      .HoverImage {
        display: flex;
        color: white;
      }
    }
    .ResetAvatar {
      display: flex;
    }
  }


  .AvatarWrap{
    display: flex;
    flex-wrap: nowrap;
    background-color: var(--bs-gray-200);
    border-radius: 100%;
    padding: 0;
    overflow: hidden;
    object-fit: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 6.25rem;
    max-width: 6.25rem;
    height: 6.25rem;

    .PreviewAvatar {
      width: 6.25rem;
      height: auto;
    }
    .HoverImage {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }

}