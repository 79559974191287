.FlowWrapper {
  width: 100%;
  height: 100%;
  flex: 1;
  overflow: hidden;
  display: flex;

  a {
    display: none !important;
  }
  .ValidText {
    font-size: 12px;
    font-weight: 600;
    color: #fc7332;
    background-color: rgba(252, 115, 50, 0.1);
    border: var(--bs-border-width) solid #fc7332;
    padding: 0.25rem 0.5rem;
    border-radius: var(--bs-border-radius);
  }
  .NodeBtnWrap {
    bottom: 120px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.08);

    button {
      border: none;
      background: #fefefe;
      border-bottom: 1px solid #eee;
      box-sizing: content-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 16px;
      height: 16px;
      user-select: none;
      padding: 5px;
    }
  }
}
.List .Search,
.FlowTools {
  height: 4rem;
  display: flex;
  align-items: center;
  padding-inline: 1rem;
  width: 100%;
}

.FlowTools {
  justify-content: space-between;
  border-bottom: var(--bs-border-width) solid var(--bs-border-color);
  background-color: #f7f7f8;
}

.Edgebutton {
  position: absolute;
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 1px solid #a8a8a8;
  cursor: pointer;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  color: #a8a8a8;
  line-height: 1;
  text-align: center;
  padding: 0;
  pointer-events: all;
  &.Selected {
    border-color: #4b4b4b;
    color: #4b4b4b;
  }
  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    background-color: #f8fbfe;
    color: var(--bs-blue);
    border-color: var(--bs-blue);
  }
}

.NodeInner {
  background: #f0f0f0;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  position: relative;
  max-width: 200px;
  min-width: 100px;
  text-align: center;
  border: 1px solid transparent;
  transition-property: background-color, border-color;
  transition-duration: 0.3s;

  &.Selected,
  &:hover {
    background-color: #edf5ff;
  }

  &.Selected {
    border-color: #ccc;
  }

  &.isNew {
    background-color: #fdf1d2;
    &.Selected,
    &:hover {
      background-color: #fedba2;
    }
  }

  button {
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: 10px;
    line-height: 1;
    text-align: center;
    padding: 0;
    font-weight: 600;
    &:hover {
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    }
  }
}

.List {
  width: 20%;
  max-width: 25rem;
  min-width: 15rem;
  border-left: var(--bs-border-width) solid var(--bs-border-color);
  background-color: #f7f7f8;
  display: flex;
  flex-direction: column;

  .Search {
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    input[type='text'] {
      border-radius: 0.375rem 0 0 0.375rem;
      text-overflow: ellipsis;
      padding-right: 0.75rem !important;
    }
  }

  ul {
    flex: 1 1 0;
    flex-basis: 0;
    overflow-y: auto;
    height: 1px;
    padding: 0;
    margin: 0;
    background-color: #fff;
    li {
      padding: 0.5rem;
      list-style: none;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      cursor: pointer;
      font-size: 14px;

      & > div {
        display: flex;
        align-items: center;
        margin-bottom: 0.25rem;
        width: 100%;
        gap: 0.25rem;

        .Badge {
          background-color: #eee !important;
        }
      }
      .IntentText {
        font-size: 13px;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
        color: #444;
      }

      &:hover {
        background-color: #f8fbfe;
      }
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        width: calc(100% - 1rem);
        height: 1px;
        background-color: var(--bs-border-color);
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
    }
  }
}
