.SummaryContainer {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding: 1rem 2rem;
}

.AgentContainer {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 1rem 2rem;
}

.BotContainer {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  padding: 1rem 2rem;
}

.Panel {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 0.625rem;
  padding: 1rem 2rem;
}
