@import '../../scss/breakpoints';

.Menus {
  background-color: #fff;
  height: 100%;
  min-width: 13rem;
  width: 13rem;
  border-right: 1px solid #e8e8e8;
  .Title {
    font-size: 0.9375rem;
    padding: 0.875rem 1rem;
    line-height: 1;
  }

  .MenuGroup {
    margin: 0 0;
    .Name {
      display: flex;
      align-items: center;
      padding: 14px 1rem 0.5rem 1rem;
      cursor: pointer;
      font-size: 0.9375rem;

      &:hover {
        /*background-color: var(--bs-gray-200);*/
        color: var(--bs-primary, #007dfc);
      }
      .Arrow {
        margin-left: 0.5rem;
        transition: all 0.25s;
        transform: rotate(-90deg);
        &.IsOpen {
          transform: rotate(0deg);
        }
      }
    }
    .Menu {
      display: flex;
      align-items: center;
      padding: 5px 1rem 5px 1.7rem;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      color: #9199a1;
      fill: #9199a1;
      svg {
        margin-right: 0.5rem;
      }
      &:hover {
        /*background-color: var(--bs-gray-200);*/
        color: var(--bs-primary, #007dfc);
        fill: var(--bs-primary, #007dfc);
      }
      &.Active {
        /*background-color: var(--bs-gray-200);*/
        color: var(--bs-primary, #007dfc);
        fill: var(--bs-primary, #007dfc);
      }
    }
  }
}

.Tab {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 0;

  .Label {
    color: #000;
    font-weight: 700;
    font-size: 1rem;
    display: flex;
    align-items: center;
    .Info {
      cursor: pointer;
      margin-left: 1rem;
    }
  }
  .Description {
    white-space: pre-wrap;
    font-size: 0.875rem;
    color: var(--bs-secondary);
    font-weight: 500;
  }
}
.PanelBorder {
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
  margin-bottom: 2rem;
}
.BorderTop {
  border-top: 1px solid #e8e8e8;
  border-radius: 0;
  padding: 2rem 1rem 1rem 0;
}
.SubBorder {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 0 1rem 0;
}
.HrColor {
  color: #e8e8e8;
  opacity: 1;
}
.TicketEventEditor {
  .Title {
    padding-bottom: 0.5rem;
  }
  .EditorContainer {
    padding: 1rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
  }
}
.ButtonContainer {
  border-top: 1px solid #e8e8e8;
  display: flex;
  width: 100%;
  text-align: end;
  justify-content: flex-end;
  gap: 0.5rem;

  .Button {
    margin-top: 1rem;
  }
}

.SubTitle {
  color: #7f7f7f;
  font-size: 0.8rem;
}

.FeedbackMessage {
  font-size: 0.75rem;
  color: var(--bs-danger);
  margin-top: 0.25rem;
}

.Line {
  height: 1px;
  width: 100%;
  left: 0;
  bottom: -1rem;
  background-color: #e8e8e8;
}

.BorderLine {
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.5rem;
}
