@import "../scss/breakpoints";

.LoginForm {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  .Form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 551px;
    .Logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 5rem;
      .Img {
        width: 90%;
        max-width: 15rem;
      }
    }
    .Reset {
      text-decoration: underline;
      color: #616161;
    }
    .InputGroup {
      max-width: 270px;

      .Row {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .Label {
          width: 100%;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: #212121;
          font-weight: 700;
          font-size: 1.25rem;
          padding-right: 1.5rem;
        }

        .Input {
          width: 100%;
          height: 5rem;
        }
        .Icon {
          z-index: 1000;
          position: absolute;
          bottom: 2.5rem;
          right: 0.2rem;
          width: 35px;
          height: 1.5rem;
          //   line-height: 1.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
