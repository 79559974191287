
.TextEllipse{
  grid-area: 1/1/2/3;
  margin-left: 0;
  margin-right: 15px;
  max-width: 100%;
  box-sizing: border-box;
}

.CustomTooltip {
}