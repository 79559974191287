@import "src/scss/breakpoints";

.RankDialog {
  @include media-breakpoint-up(sm) {
    max-width: 700px !important;
  }
  @include media-breakpoint-down(sm) {
    max-width: 500px !important;
  }

  .Header {
    font-weight: 700;
    font-size: 16px;
    color: #222222;
  }

  .Body {
    padding: 18px 16px;
  }
}
