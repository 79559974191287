@import 'src/scss/breakpoints';

.Container {
  flex: 1;
  display: flex;
  overflow-y: auto;
  flex-direction: column;

  .Search {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .AgentContainer {
    flex: 1;
    align-content: start;
    overflow-y: auto;
    margin-top: 2rem;
    display: grid;
    gap: 2rem;
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1028px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (min-width: 1700px) {
      grid-template-columns: repeat(5, 1fr);
    }
  }

  .Pagination {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
  }
}

.AgentSearchBtn {
  height: 6rem;
  display: flex;
  padding: 1rem;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  border-radius: 0.5rem;

  border: 1px solid #eef5f9;
  &:hover {
    border: 1px solid var(--default-primary, #007dfc);
    cursor: pointer;
  }

  background: var(--default-white, #fff);

  .Add {
    display: flex;
    width: 4rem;
    height: 4rem;
    padding: 0.75rem 1.25rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 62.4375rem;
    border: 1px dashed var(--default-secondary, #6c757d);
    background: var(--default-white, #fff);
  }

  .Text {
    color: var(--default-secondary, #6c757d);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
  }
}

.Select {
  width: 100%;
  margin-top: 0.5rem;
  position: absolute;
}

.Old {
  border: 1px solid #eef5f9;
}
.New {
  border: 1px solid #0bb7af;
}

.AgentCard {
  height: 6rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);

  .CardInfo {
    display: flex;
    align-items: center;
    flex: 1 0 0;
    gap: 1rem;
    .Name {
      display: block;
      width: 8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0.25rem 0 0.25rem 0;
      color: var(--gray-dark, #343a40);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
    .LoginId {
      display: block;
      width: 8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0.25rem 0 0.25rem 0;
      color: var(--gray-500, #adb5bd);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.0125rem;
    }
    .Capacity {
      margin: 0.25rem 0 0.25rem 0;
      color: var(--default-secondary, #6c757d);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
    }
  }

  .More {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0.5rem;
  }

  &:hover {
    border: 1px solid var(--default-primary, #007dfc);
    cursor: pointer;
    box-shadow: none;
    .More {
      display: block;
    }
  }
}

.Font {
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.0125rem;
}

.FontA {
  color: var(--default-secondary, #6c757d);
  font-size: 1rem;
}

.FontB {
  color: var(--default-primary, #007dfc);
  font-size: 0.875rem;
}

.FontC {
  color: var(--default-danger, #f64e60);
  font-size: 0.875rem;
}

.TeamAgentDialog {
  @include media-breakpoint-up(sm) {
    max-width: 700px !important;
  }
  @include media-breakpoint-down(sm) {
    max-width: 500px !important;
  }

  .Header {
    font-weight: 700;
    font-size: 16px;
    color: #222222;
  }

  .Body {
    padding: 18px 16px;
  }
}
