.Label {
  display: flex;
  height: auto;
  gap: 0.25rem;
  flex: 1 0 0;

  color: var(--bs-dark);
  font-weight: 700;
  font-size: 1rem;
  line-height: 100%;
}