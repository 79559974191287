.TransferDialog {
  .Header {
    font-weight: 700;
    font-size: 16px;
    color: #222222;
  }

  .Body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 8px;
    gap: 12px;
    padding: 1rem;

    .Message{
      white-space: pre-wrap;
    }

    .TabTitle {
      font-family: 'Noto Sans KR', sans-serif;
      font-size: 16px;
      letter-spacing: -0.2px;
    }

    .TabPanel {
      padding: 0;
      width: 100%;
    }

    .HistoryTitle{
      color: #009688;
      font-weight: 700;
      font-size: 1rem;
    }

  }

  max-width: 600px;
  .QuickReplyHistory {
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    letter-spacing: -0.2px;

    color: #6C757D;

    .QuickReplyHistoryTitle {
      font-weight: 700;
      color: #009688;
    }
  }
}

.Title{
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Answer{
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ModifyButton{
  cursor: pointer;
  color: #009688;
}

.DeleteButton{
  cursor: pointer;
  color:#dc3545;
}