.Nav {
  padding-inline: 4px;
  background-color: var(--qt-gray1);
  border-right: 1px solid var(--qt-gray4);
  height: 100%;
  user-select: none;
  box-shadow: 4px 0px 8px 0px #00000014;
  z-index: 1;

  & > div {
    height: 100%;
  }

  .CustomMenu {
    width: 100%;
    :global {
      .ant-menu-submenu-arrow {
        inset-inline-end: 12px;
      }
    }
  }

  .NavWrapper {
    justify-content: space-between;
    height: 100%;
    .CompanySelect {
      margin-top: 6px;
      margin-inline: 4px;
      width: calc(100% - 8px);
    }
    .Header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-inline: 14px;
      height: 56px;
      border-bottom: 1px solid var(--qt-divider);

      & > figure {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin: 0;

        .SymbolLogo {
          width: 24px;
          height: 24px;
          cursor: pointer;
          object-fit: contain;
        }
      }
      .LogoImg {
        width: 110px;
        height: 24px;
        object-fit: contain;
      }
      .CollapsedBtn {
        cursor: pointer;
        color: var(--qt-cool-gray4);
        &:hover {
          color: var(--qt-cool-gray7);
        }
      }
    }
    .ProfileWrapper {
      padding-bottom: 12px;
      padding-top: 6px;
      padding-inline: 4px;
    }
    .MainMenu {
      flex: 1;
      overflow-y: auto;
    }
    .TicketTitle {
      margin: 0;
      padding-block: 8px;
      padding-inline: 24px;
      line-height: 20px;
      color: var(--qt-ch-secondary);
      white-space: nowrap;
      transition: 0.2s;
      overflow: hidden;
    }
  }

  .Footer {
    .NoticeWrapper {
      height: 182px;
      margin: 8px;
      margin-bottom: 12px;
      position: relative;
      overflow: hidden;
      .Notice {
        border-radius: 4px;
        background-color: var(--qt-gray3);
        position: absolute;
        top: 0;
        left: 0;
        transition: 0.2s;
        width: 100%;
        min-width: 185px;
        height: 100%;
      }
    }
  }

  &.Collapsed {
    .Header {
      justify-content: center;
    }
    .TicketTitle {
      height: 0px;
      padding-block: 0;
    }
    .ProfileWrapper {
      padding-inline: 0;
    }
    .NoticeWrapper .Notice {
      top: 100%;
    }
  }
}

.Wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}

.Line {
  display: block;
  height: 1px;
  width: 100%;
  background-color: var(--qt-divider);
}
.Badge {
  :global {
    .ant-badge-dot {
      top: 50%;
      box-shadow: 0 0 0 2px var(--qt-gray2);
    }
  }
}
