.Badge {
  :global {
    .ant-badge-dot {
      box-shadow: 0 0 0 2px var(--qt-gray1);
      top: auto;
      right: 2px;
      bottom: 0px;
    }
  }
  &.Hidden {
    :global {
      .ant-badge-dot {
        opacity: 0;
      }
    }
  }
}
.Avatar {
  border: 1px solid var(--qt-gray1);
}
