.Menu {
  display: flex;
  flex-direction: column;
}

.MenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: 4px;
  padding-inline: 12px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-block: 8px;
    padding-inline: 12px;
    gap: 8px;
    border-radius: 4px;
    transition: background-color 0.2s;
    color: var(--qt-cool-gray6);

    [data-status] {
      display: flex;
      align-items: center;
      border: none;
      padding-inline: 8px;
      height: 24px;
      border-radius: 12px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: 0;
      min-width: 37px;
      transition: 0.3s;
    }

    [data-status='ing'] {
      background-color: var(--qt-ch-primary-inverse);
      color: var(--qt-cool-gray5);
      border: 1px solid var(--qt-cool-gray5);
    }
    [data-status='complete'] {
      color: var(--qt-ch-primary-inverse);
      background-color: var(--qt-cool-gray5);
      border: 1px solid var(--qt-cool-gray5);
    }

    &:hover {
      color: var(--qt-cool-gray7);
      background-color: var(--qt-gray3);

      & > [data-status='ing'] {
        color: var(--qt-cool-gray7);
        border: 1px solid var(--qt-cool-gray7);
      }
      & > [data-status='complete'] {
        background-color: var(--qt-cool-gray7);
      }
    }
    &:active {
      background-color: var(--qt-bg-hover);
    }
    .Icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 16px;
      min-height: 16px;
    }
    .Text {
      flex: 1;
      min-width: 0;
      text-align: left;
      max-width: 100%;
    }
  }
  &.isActive {
    button {
      color: var(--qt-primary6);
      background-color: var(--qt-primary1);
      [data-status] {
        color: var(--qt-ch-primary-inverse);
        background-color: var(--qt-primary6);
        border-color: var(--qt-primary6);
      }
    }
  }

  &.Block {
    padding: 0;
    border-top: 1px solid var(--qt-divider);

    button {
      padding-block: 12px;
      padding-inline: 20px;
      border-radius: 0;
    }
  }
  &.Collapsed {
    padding-inline: 0;
    button {
      width: auto;
      min-width: 36px;
      padding-inline: 8px;
      gap: 0;
      .Text {
        width: 0;
        max-width: 0%;
        opacity: 0;
      }
    }
    &.Block {
      button {
        width: 100%;
      }
    }
  }
}
