.OperationWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .Header {
    display: flex;
    align-items: center;
    padding-inline: 1rem;
    height: 56px;
    min-height: 56px;
    border-bottom: 1px solid var(--qt-divider);
    gap: 0.5rem;
    .Title {
      display: flex;
      align-items: center;
    }
    .ArrowIcon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .IframeWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;

    .ResultWrapper {
      width: 100%;
      height: 100%;
      align-content: center;
    }

    .Loading {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      pointer-events: none;
    }

    .Iframe {
      flex: 1;
      width: 100%;
      border: none;
    }
  }
}
