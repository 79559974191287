/*
.Panel{
  border: 1px solid #E0E0E0;
  border-radius: 0.625rem;
  background-color: #fff;
  padding: 1rem 2rem;
  margin-bottom: 0.625rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
}*/
.Panel {
  width: 100%;
  padding: 1rem 2rem;
}
