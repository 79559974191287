.DetailWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.Fill {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}

.Textarea {
  :global .input-group {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    textarea {
      height: 100%;
      width: 100% !important;
      resize: none;
    }
  }
}

.UploadInfo {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  gap: 30px;
  dl {
    font-size: 13px;
    color: #444;
    display: flex;
    gap: 5px;
    margin: 0;

    dd {
      margin: 0;
    }
  }
}

.UploadWrap {
  display: flex;
  align-items: center;
  gap: 20px;

  .FileList {
    margin: 0;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
  }
}
