/*
.Panel{
  border: 1px solid #E0E0E0;
  border-radius: 0.625rem;
  background-color: #fff;
  padding: 1rem 2rem;
  margin-bottom: 0.625rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
}*/
.Panel {
  width: 100%;
  background-color: #fff;
  /*padding: 1rem 2rem;*/
  padding: 1rem 2rem;

  .Line {
    height: 1px;
    width: 100%;
    left:0;
    bottom:-1rem;
    background-color: #e8e8e8;
  }
}