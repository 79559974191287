@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:400,500,600,700&display=swap');

html {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1 1 0;
  -ms-flex: 1 1;
  min-height: 0;
  min-width: 0;
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* mobile viewport bug fix */

  flex: 1 1 0;
  min-height: 0;
  min-width: 1400px;
  display: flex;
}

#root {
  flex: 1 1 0;
  width: 100%;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.flex-default {
  flex: 1 1 0;
  -ms-flex: 1 1;
  min-height: 0;
  min-width: 0;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

* {
  position: relative;
}

.form-control {
  padding-right: calc(2.4em) !important;
}

.SingleDatePicker {
  display: flex !important;
  flex: 1 !important;
}
.SingleDatePicker__block {
}

.SingleDatePicker_picker {
  z-index: 9999 !important;
}

.SingleDatePickerInput {
  display: flex !important;
  align-items: center !important;
}

.SingleDatePickerInput_calendarIcon {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  padding: 0 !important;
}

.DateRangePickerInput {
  display: flex !important;
  align-items: center !important;
}

.DateRangePickerInput_calendarIcon {
  display: flex !important;
  justify-content: center !important;
  align-content: center !important;
  padding: 0 !important;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyOC4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDYgNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0M4QzhDODt9DQo8L3N0eWxlPg0KPGc+DQoJPGc+DQoJCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0zLjQsNS43QzMuMSw1LjUsMy4xLDUuMSwzLjMsNC45bDEuNi0xLjZjMC4yLTAuMiwwLjYtMC4xLDAuOCwwLjFDNiwzLjcsNi4xLDQuMSw1LjksNC4yTDQuMyw1LjkNCgkJCUM0LjEsNi4xLDMuNyw2LDMuNCw1Ljd6Ii8+DQoJPC9nPg0KCTxnPg0KCQk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMC4xLDUuNkMwLDUuNCwwLDUuMSwwLjIsNC44bDQuNi00LjZDNS4xLDAsNS40LTAuMSw1LjYsMC4xYzAuMiwwLjIsMC4xLDAuNi0wLjEsMC44TDEsNS41DQoJCQlDMC43LDUuOCwwLjMsNS44LDAuMSw1LjZ6Ii8+DQoJPC9nPg0KPC9nPg0KPC9zdmc+DQo=');
  background-size: 8px 8px;
  /*background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='rgb(211, 211, 211)' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M3.646 10.146a.5.5 0 0 1 .708 0L8 13.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-4.292a.5.5 0 0 0 .708 0L8 2.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM1 8a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1h-13A.5.5 0 0 1 1 8z'%3E%3C/path%3E%3C/svg%3E");*/
  /*background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');*/
  background-position: bottom right;
  padding: 0 3px 3px 0;
}

.card.table-box .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-origin: content-box;
  box-sizing: border-box;
  background: transparent;
  padding: 0 3px 3px 0;
}

.card.table-box.no-resize .react-resizable-handle {
  display: none;
}

.card.table-box .react-resizable-handle-n {
  transform: translateY(-50%);
}
.card.table-box .react-resizable-handle-s {
  transform: translateY(50%);
}
.card.table-box .react-resizable-handle-w {
  transform: translateX(-50%);
}
.card.table-box .react-resizable-handle-e {
  transform: translateX(50%);
}

.card.table-box .react-resizable-handle-nw {
  transform: translate(-50%, -50%);
}
.card.table-box .react-resizable-handle-ne {
  transform: translate(50%, -50%);
}
.card.table-box .react-resizable-handle-sw {
  transform: translate(-50%, 50%);
}
.card.table-box .react-resizable-handle-se {
  transform: translate(50%, 50%);
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(270deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}

.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

/*
 * Container style
 */
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
  display: block;
  opacity: 1;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-y {
  display: block;
  opacity: 1;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: #eee;
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 6px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 6px;
  /* there must be 'right' for ps__thumb-y */
  right: 2px;
  /* please don't change 'position' */
  position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}
.scrollbar-container {
  position: relative;
  height: 100%;
}

/* 
.hEACtv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  margin: 0px !important;
  height: 17px !important;
  border-top: 1px solid rgba(0, 0, 0, 1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 1) !important;
  cursor: col-resize;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}


.hEACtv::before {
  content: "" !important;
  border-top: 2px solid #000 !important;
  border-bottom: 2px solid #000 !important;
  position: absolute !important;
  display: inline-block !important;
  width: 20px !important;
  height: 7px;
  margin: 3px 0 !important;
}

.hEACtv:hover {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-top: 1px solid rgba(0, 0, 0, 1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 1) !important;
} */

/* .cjjWdp{
  background-color: transparent !important;
} */

.Mui-selected {
  color: #009688 !important;
}

.MuiTabs-indicator {
  background-color: #009688 !important;
}

.IdBadge {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

/* 
.fXAXjb {
  width: 14px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative !important;
  margin: 0px !important;
  height: 17px !important;
  cursor: col-resize;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}


.fXAXjb::before {
  content: "" !important;
  border-left: 2px solid #000 !important;
  border-right: 2px solid #000 !important;
  position: absolute !important;
  display: inline-block !important;
  width: 8px !important;
  height: 20px;
  margin: 1px 0 !important;
  top: 2150%;
}

.fXAXjb:hover {
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  background-color: rgba(0, 0, 0, 0.5) !important;
} */

/* sc-bwzfXH hEACtv sc-bdVaJa cjjWdp */
/* sc-htpNat fXAXjb sc-bdVaJa cjjWdp */
/* .sc-bdVaJa.cjjWdp{
  opacity: 0.5;
  border-width: 2px;
  margin: 0;

}
.sc-bdVaJa.cjjWdp:hover{
  border-width: 2px;
}
 */
.sc-bdVaJa.cjjWdp {
  opacity: 1;
  background-color: #dee2e6;
}
.sc-bdVaJa.cjjWdp::before {
  content: '';
  position: absolute;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 2px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="rgba(0,0,0,0.2)" viewBox="0 -960 960 960"><path d="M160-360v-80h640v80H160Zm0-160v-80h640v80H160Z"/></svg>');
  left: calc(50% - 12px);
  top: -7px;
  width: 24px;
  height: 14px;
}
/* 세로 */
.sc-bdVaJa.fXAXjb:before {
  transform: rotate(-90deg);
  top: calc(50% - 12px);
}
/* 가로 */
.sc-bdVaJa.hEACtv::before {
}
