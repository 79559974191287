.QPlayWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  background-color: var(--qt-gray3);
  // border-bottom: 1px solid var(--qt-divider);
  audio {
    flex: 1;
  }

  .Button {
    align-items: center;
    display: flex;
    background: var(--qt-cool-gray1);
    border: 1px solid var(--qt-cool-gray2);
    padding: 0.5rem;
    border-radius: 4px;
  }
}
