.LoaddingIcon {
  font-size: 30px;
  color: var(--qt-cool-gray4);
}
.NoticeCarousel:global {
  &.ant-skeleton-element {
    width: 100%;
    height: 100%;
    .ant-skeleton-image {
      width: 100%;
      height: 100%;
    }
  }
}

.NoticeCarousel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  .Header {
    padding-inline: 12px;
    height: 36px;
    border-bottom: 1px solid var(--qt-divider);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .Title {
      color: var(--qt-cool-gray6);
    }
    .Controller {
      display: flex;
      align-items: center;
      gap: 3px;
      color: var(--qt-cool-gray4);
      letter-spacing: 1.5px;

      .Icon {
        font-size: 10px;
        cursor: pointer;
        min-width: 10px;
        &:hover {
          color: var(--qt-cool-gray7);
        }
      }
    }
    .CloseIcon {
      font-size: 12px;
      color: var(--qt-cool-gray6);
      cursor: pointer;
      &:hover {
        color: var(--qt-cool-gray9);
      }
    }
  }
  ul {
    padding: 0;
    margin: 0;
    overflow: hidden;
    flex: 1;
    min-height: 0;
    li {
      padding: 12px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: 4px;

      .ItemTitle {
        color: var(--qt-ch-primary);

        .LinkIcon {
          display: none;
        }
        &.LinkActive {
          cursor: pointer;
          &:hover {
            color: var(--qt-ch-active);
            text-decoration: underline;
          }
          .LinkIcon {
            display: inline-flex;
            margin-right: 3px;
          }
        }
      }
      .Content {
        color: var(--qt-cool-gray6);
        line-height: 17px;
      }
    }
    &.Grab {
      li {
        cursor: grab;
        &:active {
          cursor: grabbing;
        }
      }
    }
  }
}
