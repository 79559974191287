.DragLayerPopup {
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  pointer-events: bounding-box;
  user-select: none;
  z-index: 999;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  .Header {
    display: flex;
    cursor: grab;
    justify-content: space-between;
    align-items: center;
    color: var(--qt-gray1);
    background-color: var(--qt-primary6);
    padding: 8px 12px;
    .Title {
      font-size: 15px;
      font-weight: 500;
    }
    .ButtonWrap {
      display: flex;
      align-items: center;
      gap: 4px;
      .Fold {
        &:hover {
          background-color: var(--qt-primary5);
        }
      }
      .Close {
        &:hover {
          background-color: var(--qt-ch-danger);
        }
      }
      .Fold,
      .Close {
        height: 22px;
        aspect-ratio: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        color: var(--qt-gray1);
        transition: background-color 0.3s;
      }
    }
  }
  .Content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: auto;

    &.Fold {
      height: 0;
    }
  }
}
