.SearchContentWrap {
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
}
.SearchSetting {
  padding: 1rem 0 0 0;
  border-top: 1px solid #e8e8e8;
  margin-top: 1rem;
}
.SearchContent {
  padding: 1rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .SubTitle {
    font-weight: bold;
    color: var(--bs-dark);
    margin-right: 1rem;
  }
}

.Button {
  margin-left: 10px;
  width: 62px;
  height: 40px;
  border-radius: 0.5rem;
}

.DropDownButton {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  border-bottom: 1px solid var(--gray-200, #eef5f9);
  padding: 1rem;
  svg {
    margin-right: 1rem;
  }
}

.CopyButton {
  display: flex;
  border: 0;
  background-color: #ffffff;
}

.BackDrop {
  right: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;

  display: flex;
  justify-content: center;
  align-items: center;
}

.Container {
  padding: 2rem;
  display: flex;
  overflow: hidden;
  flex: 1;
  width: 100%;
  [data-type='Resizer'] {
    margin-left: 0.6rem !important;
  }

  .ChatContainer {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    border: 1px solid #eee;
    overflow: hidden;
    border-radius: 0.5rem;
  }
  .InfoContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: stretch;
    overflow: auto;

    .InfoCard {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: stretch;
      flex-grow: 1;
      min-width: 10rem;
      margin: 0 0.8rem 0 0.8rem;
      .InfoItem {
        display: flex;
        flex: 1;
        .InfoTitle {
          align-self: center;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
        .InfoContent {
          flex: 1;
          color: #6c757d;
          margin-left: 0.3rem;
          align-self: center;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }
      }

      .Table {
        display: flex;
        flex-direction: column;
        .Thead {
          padding: 0.5rem 0 0.5rem 0;
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
          margin-bottom: 0.5rem;
          .Th {
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
          }
        }
        .Tbody {
          max-height: 10rem;
          overflow-y: auto;
          .Tr {
            width: 100%;
            padding: 0.5rem 0 0.5rem 0;
            border-bottom: 1px solid #eee;
            .Td {
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .Memo {
        background-color: #f7f7f7;
        border-radius: 4px;
        word-break: break-word;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.375rem;
        margin-bottom: 0.25rem;
        .MemoInfo {
          font-size: 0.75rem;
        }

        .Remove {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #aaa;
          border: 0;
          color: #f7f7f7;
          border-radius: 14px;
          width: 14px;
          height: 14px;
          margin-left: 1rem;
          margin-right: 0.375rem;
        }
      }

      :global {
        .accordion-button {
          padding: 0.4rem;
          font-size: 1rem;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -0.0125rem;
          background-color: #ffffff;
          border-bottom: 0;
        }
        .accordion-body {
          flex: 1;
          padding: 1rem;
          overflow: hidden;
        }
      }
    }
  }
}

.IconBadge {
  font-size: 16px !important;
  display: flex !important;
  padding: 4px 8px !important;
  gap: 5px;
}
