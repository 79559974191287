

.BorderLine {
  border: 1px solid #E8E8E8;
  border-radius: 0.5rem;
}


.Label {
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: .5rem;
  color: var(--bs-dark);
}

.Button {
  margin-left: 10px;
  width: 6rem;
  height: 2.5rem;
  border-radius: .5rem;
}
