@import '../../scss/breakpoints';

.IconList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.Icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:hover{
    .DeleteIcon{
      display: block;
    }
    .IconWrap {
      background: var(--gray-200, #EEF5F9);
      border: 1px solid var(--bs-primary, #007DFC);
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.05);
    }
    .IconName {
      color: var(--bs-primary);
    }
  }

  .IconWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem 2.5rem;
    border: 1px solid var(--bs-gray-200);
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.05);

    transition: all 0.2s;


    .Image {
      width: 2.25rem;
      height: 2.25rem;
      img{
        width: 100% ;
        height: 100% ;
      }
    }
  }
  .IconName{
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 100%;
    color: var(--bs-gray-800);

    transition: all 0.2s;
  }
  .DeleteIcon {
    display: none;
    position:absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    text-align: center;
    color: var(--bs-gray-800);

    &:hover {
      color: var(--bs-danger);
    }

    &.Active {
      display: block;
      position :absolute;
    }
  }
}

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid var(--bs-gray-400);
}