.DatainfoWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40%;

  .CardWrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    :global {
      .card {
        border: 1px solid var(--qt-divider);
        background-color: var(--qt-ch-primary-inverse)
      }

      .card-highlight {
        background-color: var(--qt-blue1);
      }

      .card-header {
        background-color: var(--qt-gray2);
        border-bottom: 1px solid var(--qt-divider);
      }

      .card-header-highlight {
        background-color: var(--qt-blue2);
      }
    }

    .Card {
      margin-bottom: 14px;
      flex-grow: 1;
    }
  }
}

.DataGridWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 20px;
  width: 55%;
  flex-grow: 1;
}

.InputWrapper {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--qt-gray1);
  border: 1px solid var(--qt-divider);
  border-radius: 4px;

  :global {
    .form-control:disabled {
      background-color: var(--qt-gray3);
    }
  }
}

.SearchForm,
.SearchContent {
  height: 100%;
}

.SearchContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  button {
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
