.Label {
  display: flex;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.DetailWrapper {
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  overflow: hidden;
  height: 100%;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  .Header {
    min-height: 56px;
    border-bottom: var(--bs-border-width) solid var(--bs-border-color);
    padding: 12px 24px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    :global dl {
      display: flex;
      gap: 8px;
      margin: 0;
      &.center {
        align-items: center;
      }
      &.block {
        flex: 1;
      }

      input[type='text'] {
        padding: 0.375rem 0.75rem !important;
      }

      dt {
        font-size: 14px;
        line-height: 38px;
        font-weight: 700;
        white-space: nowrap;
      }
      dd {
        margin: 0;
        max-width: 200px;
      }
    }
    .ButtonWrap {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
}

.Panel {
  flex: 1;
  padding: 2rem;
}
