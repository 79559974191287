.Count {
  text-align: right;
  font-weight: 700;
  .Name {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.SubCount {
  display: flex;
  justify-content: flex-end;
  color: var(--bs-gray-500);
  .Name {
    font-size: 1rem;
    margin-right: 1rem;
  }
}

.Border {
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
}
