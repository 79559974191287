.Container {
  padding: 1rem 2rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  .Wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }
  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .Title {
      display: flex;
      align-items: center;
      gap: 5px;
      span {
        font-size: 1rem;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  .Panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
  }
  .TopArea {
    display: flex;
    gap: 5px;
    padding-bottom: 0.5rem;
    button {
      white-space: nowrap;
    }
    .Search {
      flex: 1;
    }
  }
  .Data {
    padding: 0;
    flex: 1;
  }

  .Label {
    display: flex;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .Button {
    margin-left: 10px;
    width: 6rem;
    height: 2.5rem;
    border-radius: 0.5rem;
  }
  .Form {
    display: flex;
    flex-direction: column;
    flex: 1;
    textarea {
      resize: none;
    }
  }
}

.HFull {
  height: 100%;
  * {
    height: 100%;
  }
}
