.Caption {
  font-size: 12px;
}
.Text {
  color: inherit;
}
.Text:global {
  &.ant-typography,
  &.ant-typography p {
    margin-bottom: 0;
  }
}
