@import '../../scss/breakpoints';

.Container{
  width:100%;
  height:100%;
  display: flex;
  flex-direction: column;
}

  .Label {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
    flex: 1 0 0;

    color: var(--bs-gray-800);
    font-weight: 700;
    font-size: 1rem;
  }



.BorderLine {
  border: 1px solid var(--bs-gray-400);
  border-radius: 0.5rem;
}
.AddIcon {
  width: 100%;
}
