.TabItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.FileUpload {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  padding: 1rem;

  .ButtonWhiteSpace {
    white-space: nowrap;
  }
}
.UrlUpload {
  width: 100%;
  display: flex;
  flex-direction: row;

  padding: 1rem;
}

.ImagePreview {
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--bs-border-color);
  min-width: 20rem;
  max-width: 20rem;
  height: 20rem;

  overflow: hidden;


  .PreviewAvatar{
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;

    color:var(--bs-secondary);
    font-size: 1.25rem;

  }
}

.Title {
  color: var(--bs-dark);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.0125rem;
  padding-bottom: 1rem;
}

.Footer {
  border-top:1px solid var(--bs-border-color);
  padding-top: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;

}
