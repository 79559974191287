@import '../../scss/breakpoints';

.TicketDetail {
  gap: 6px;
  display: flex;
  flex-direction: column;
  grid-gap: 6px;
  height: 100%;

  [data-type='Resizer'] {
    background-color: var(--qt-divider);
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    &:hover {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
    }
    &::before {
      background-color: var(--qt-gray1);
      border-color: var(--qt-cool-gray3);
    }
  }
}

.Header {
  background-color: var(--qt-gray1);
  border-bottom: 1px solid var(--qt-divider);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  height: 56px;
}

.TabContainer {
  background-color: var(--qt-gray1);
  padding-left: 0.43rem;
  padding-right: 0.43rem;
  height: 100%;
  .TabTitle {
    font-size: 0.875rem;
    font-weight: 700;
    padding-left: 0;
    padding-right: 0;
    font-family: 'Noto Sans KR';
  }
}

.CardRow {
  height: 100%;
  display: flex;
  flex: 1 1 0;
  gap: 1px;
  background-color: var(--qt-divider);
}

.CardWrap {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.Card {
  overflow: hidden;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  background: var(--qt-gray1);
  padding-top: 12px;

  .CardHeader {
    font-weight: 500;
    font-size: 15px;
    color: var(--qt-ch-primary);
    padding-inline: 12px;
  }

  .CardContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .MemoWrap {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
      padding: 0 12px 12px;
      .Memo {
        width: 100%;
        border-radius: 4px;
        gap: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background-color: var(--qt-cool-gray1);
        padding: 8px 12px;
        .MemoContent {
          font-size: 13px;
        }
        .MemoInfo {
          display: flex;
          align-items: center;
          width: 100%;
          gap: 5px;
          .MemoName {
            color: var(--qt-cool-gray8);
            font-weight: 500;
            flex: 1;
          }
          .MemoAt {
            color: var(--qt-ch-secondary);
            font-weight: 500;
          }
          .Remove {
            cursor: pointer;
            color: var(--qt-cool-gray6);
            min-width: 16px;
            min-height: 16px;
          }
        }
      }
    }
  }
}

.Record {
  display: flex;
  align-items: flex-start;
  width: 100%;

  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #222222;

  > .Label {
    display: flex;
    flex: 0 2 110px;
  }

  > .Value {
    display: flex;
    flex: 1 1 110px;
  }

  .Rating {
    color: #2c8dff;
  }
}

.BranchMemo {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.2px;
  color: #222222;
}

.Input {
  width: 100%;
  height: auto;
  max-height: 100px;
  background: white;

  border: 1px solid #e3e3e3;
  border-left: none;
  border-right: none;
  display: flex;

  .InputInner {
    padding: 16px;
    border: 0;
    width: 100%;
    background: white;
    outline: none;
    font-size: 14px;
    flex: 1 1 0;
    resize: none;
  }

  .SendIconWrap {
    padding: 16px;
    background: none;
    border: 0;
    &.Active > svg {
      stroke: #2c8dff !important;
    }
  }
}

.IconButton {
  background: none;
  border: 0;
}

.Holidays {
  color: #f24822;
}

.LocationBadge {
  align-items: center;
  justify-content: center;
  padding: 2px 4px;

  background: #fed543;
  border-radius: 2px;

  font-weight: 500;
  font-size: 12px;

  max-width: 74px;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.DropDownNoIcon {
  padding: 5px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &::after {
    display: none;
  }
}

.RecommendContent {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 16px;
  li {
    form {
      display: flex;
      gap: 8px;
      .Textarea {
        height: 80px;
        flex: 1;
        font-size: 13px;
      }
      .ButtonWrap {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;
      }
    }
  }
}

.NoticeList {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 16px;
  overflow: hidden;
  li {
    background-color: #f0f0f0;
    padding: 10px 12px;
    display: flex;
    flex-direction: column;
    gap: 6px;
    .NoticeTitle {
      display: block;
      width: 100%;
      overflow: hidden;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .NoticeInfo {
      font-size: 11px;
      color: #666;
      display: flex;
      justify-content: space-between;
      gap: 5px;
    }
    .NoticeContent {
      font-size: 12px;
      max-height: 100px;
      margin: 0;
      overflow-y: auto;
    }
    .NoticeLinks {
      font-size: 13px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}
