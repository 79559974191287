.ExcelIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  z-index: 1;
  color: var(--bs-success);
  &:hover {
    color: var(--bs-white);
  }
}
.ModalExcelIcon {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--bs-success);
  &:hover {
    color: var(--bs-white);
  }
}
