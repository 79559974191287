.Container {
  .Panel {
    /*border-radius: 0.5rem;*/
    background: var(--white, #FFF);
    /*box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);*/

    .Content {
      background-color: #EEF5F9;
      border-radius: .5rem;
      padding: 1rem;
      margin: 0px;
    }
  }

  .Title {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    align-self: center;
  }

  .Label {
    display: flex;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: .5rem;
  }

  .Button {
    margin-left: 10px;
    width: 6rem;
    height: 2.5rem;
    border-radius: .5rem;
  }
}