.DropDown {
  width: 177px;
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0px 6px 16px 0px #00000014;
  background-color: var(--qt-ch-primary-inverse);
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0;
  li {
    padding-inline: 24px;
    height: 36px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    span {
      color: var(--qt-ch-primary);
    }
    &:hover {
      background-color: var(--qt-gray3);
    }
    &.Active {
      span {
        color: var(--qt-primary6);
      }
      background-color: var(--qt-primary1);
    }
  }
}
