.Profile {
  cursor: pointer;
  border-radius: 4px;
  padding: 12px;
  background-color: var(--qt-gray3);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  transition: background-color 0.2s;
  &:hover {
    background-color: var(--qt-bg-hover);
  }
  .Content {
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;
    gap: 8px;

    .ProfileInfo {
      margin: 0;
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;
      span:first-of-type {
        color: var(--qt-ch-primary);
      }
      span:last-of-type {
        color: var(--qt-cool-gray6);
      }
    }
  }
  .Icon {
    display: flex;
    font-size: 14px;
    color: var(--qt-ch-secondary);
  }
}
