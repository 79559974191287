@import '../scss/breakpoints';

@keyframes tab-blinking {
  from {
    background-color: inherit;
  }
  to {
    background-color: var(--qt-primary1);
  }
}

@keyframes tab-red-blinking {
  from {
    background-color: inherit;
  }
  to {
    background-color: rgba(241, 8, 8, 0.39);
  }
}

// .ChatView {
// }
.HeaderCategory {
  border-bottom: 2px solid var(--gray-200, #eef5f9);
  background-color: #f0f1f2;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.ChatHeader {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  padding-bottom: 0;
  background-color: #f0f1f2;

  .NameContainer {
    font-size: 13px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    .Badge {
      display: flex;
    }
    .Name {
      flex: 1 1 auto;
      width: 0;

      margin-left: 0.5rem;
      margin-right: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-size: 1rem;
    }
  }
  .TicketStatus {
    display: flex;
    margin-right: 1rem;
    align-items: center;
  }

  .DropDownButton {
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    border-bottom: 1px solid var(--gray-200, #eef5f9);
    padding: 1rem;
    svg {
      margin-right: 1rem;
    }
  }
  .CloseButton {
    color: var(--bs-secondary);
    cursor: pointer;
  }
}

.ScenarioArea {
  width: 10rem;
  // border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-flow: column;
  overflow: hidden;
  .ScenarioTop {
    display: flex;
    align-items: center;
    padding: 0 0.938rem;
    width: 100%;
    min-height: 57px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background-color: #fff;
  }
  @keyframes blink-effect {
    50% {
      opacity: 0;
    }
  }

  .ScenarioWrap {
    width: 100%;
    height: auto;
    flex: auto;
    overflow: auto;
    background-color: #f7f7f7;
    padding: 0.469rem 0.938rem;
    .ScenarioItem {
      font-weight: bold;
      padding-top: 0.469rem;
      padding-bottom: 0.469rem;
      line-height: 1.4;
      position: relative;
      color: rgba(73, 75, 87, 1);

      .Label {
        color: #fff;
        display: inline-block;
        margin-right: 0.3rem;
        padding: 0.1rem 0.3rem;
        font-size: 0.75rem;
        background-color: rgba(73, 75, 87, 1);
      }
      .Text {
        display: inline-block;
        font-size: 0.875rem;
        letter-spacing: -1;
      }
    }
    // .Used{
    //   .Label{
    //     background-color: rgba(73,75,87,1);
    //   }
    //   color: rgba(73,75,87,1);
    // }
    .Current {
      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: -15px;
        width: 0;
        height: 0;
        border-bottom: 4px solid transparent;
        border-top: 4px solid transparent;
        border-left: 4px solid #059e94;
        border-right: 4px solid transparent;
        animation: blink-effect 1s step-end infinite;
      }
      .Label {
        background-color: #059e94;
      }
      color: #059e94;
    }
    .Yet {
      .Label {
        background-color: rgba(73, 75, 87, 0.4);
      }
      color: rgba(73, 75, 87, 0.4);
    }
  }
}

.ChatView {
  width: 500px;
  min-width: 500px;
  border-right: 1px solid var(--qt-divider);
}

.TicketPreview {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  flex: 1 1 0;
  cursor: pointer;
  border-left: 2px solid transparent;
  &:hover {
    background-color: rgb(240, 240, 240);
  }

  &.Active {
    background: #e9ecef;
    border-left: 2px solid #009688;
  }

  &.Blink {
    animation-duration: 0.5s;
    animation-name: tab-blinking;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  &.RedBlink {
    animation-duration: 0.5s;
    animation-name: tab-red-blinking;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  .TicketDisabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Info {
    display: flex;
    flex: 1 1 0;
    justify-content: space-between;
    overflow: hidden;

    .Header {
      display: flex;
      gap: 6px;

      .Agent {
        padding: 2px 4px;
        font-weight: 500;
        font-size: 10px;
        letter-spacing: -0.6px;
        color: #17a2b8;
      }
    }

    .Name {
      font-weight: 500;
      font-size: 1rem;
      letter-spacing: -0.2px;
      color: #424242;
    }

    .Time {
      font-weight: 400;
      font-size: 0.75rem;
      letter-spacing: 0.15px;
      color: #aaaaaa;
    }

    .LeftIcon {
      padding-left: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #7f7f7f;
    }
  }

  .Content {
    display: flex;
    .Message {
      font-weight: 400;
      font-size: 0.75rem;
      letter-spacing: 0.15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #424242;
    }
    .Badge {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 6px;
      background: #ff0000;
      border-radius: 9px;

      font-weight: 700;
      font-size: 10px;
      letter-spacing: 0.15px;

      color: #ffffff;
    }
  }
}

.FastRefundBadge {
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 12px;
  max-width: 74px;
  background-color: #bae1ff;
  color: #283747;
  width: 2rem;
}

.TicketTabs {
  display: flex;
  justify-content: space-between;
  background-color: var(--qt-gray1);
  border-bottom: 1px solid var(--qt-divider);
  height: 56px;

  .TicketTab {
    flex: 1 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #aaaaaa;
    padding: 6px;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.15px;
    cursor: pointer;
    transition: color 0.2s ease-in-out;

    &.Active {
      color: #009688;
    }

    .TicketTabIcon {
      padding: 6px;
    }
  }
}

.HistoryChatView {
  flex: 1;
  flex-direction: column;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  .CustomContent {
    background-color: rgb(108, 117, 125, 0.1);
  }

  :global(.sendbird-conversation) {
    border: none;
  }

  :global(.sendbird-channel-list) {
    flex: 1 1 0;
  }

  .HistoryChatHeader {
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    padding: 8px;

    .ClosedStatus {
      padding-left: 0.125rem;
      padding-right: 0.125rem;
      font-weight: 500;
      font-size: 0.75rem;
      color: #343a40;
      background-color: #e2e2e2;
      border-radius: 0.25rem;
    }

    .Categories {
      margin-left: 0.5rem;
      font-size: 0.75rem;
      color: #343a40;
    }

    .Clear {
      cursor: pointer;
      padding: 0.6rem;
    }

    .Info {
      font-weight: 500;
      font-size: 12px;
      letter-spacing: -0.4px;

      &.Data {
        color: #17a2b8;
      }
    }
  }
}

.ChatViewPort {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;

  :global(.sendbird-conversation) {
    border: none;
  }

  :global(.sendbird-channel-list) {
    flex: 1 1 0;
  }
}

.DetailContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  height: 100%;
  width: 0;
  // border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.LeftArea {
  display: flex;
  flex-direction: column;
  width: 12.5rem;
  height: 100%;
  border-right: 1px solid var(--qt-divider);
  background-color: var(--qt-cool-gray1);
  overflow: hidden;

  .TicketWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    min-height: 0;
    .TabPanel {
      display: flex;
      flex-direction: column;
    }
    .TicketList {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      min-height: 0;
      .ChannelSearch {
        background-color: #fff;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--qt-divider);
        &:focus-within {
          border-color: var(--qt-primary6);
        }
        :global {
          .ant-form-item {
            margin: 0;
          }
          .ant-input-affix-wrapper {
            z-index: 0;
            border-radius: 0;
            background-color: #fff;
            border-color: transparent !important;
            padding: 4px 0px 4px 8px;
            font-size: 13px;
          }
          .ant-btn {
            border-radius: 0;
            border: none;
          }
        }
      }
    }

    .TicketListProfile {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      height: 37px;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      background-color: #f7f7f7;
      .TicketListNickname {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.125rem;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: 0.15px;
        color: #222222;
      }
    }

    .HistoryHeader {
      font-size: 14px;
      font-weight: 500;
      color: var(--qt-gray1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .Icon {
        font-size: 12px;
        transform: rotate(180deg);
        transition: transform 0.3s;

        &.Open {
          transform: rotate(0);
        }
      }
    }
    .HistoryContent {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      flex-direction: column;
      background-color: var(--qt-gray1);
    }
    .HistoryList {
      display: flex;
      flex-direction: column;
      min-height: 0;
    }
  }
  .NoticeWrapper {
    height: 240px;
    background-color: var(--qt-gray3);
    border-top: 4px solid var(--qt-divider);
  }
}

.NotClosed {
  border-left: 4px solid var(--bs-danger);
  animation-name: NewRoomAgentCall;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.Accordion {
  :global {
    .accordion-item {
      border: none;
      overflow: hidden;
      border-radius: 0 !important;
    }
    .accordion-button {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding: 12px 10px;
      box-shadow: none !important;
      z-index: 0 !important;
      background-color: var(--qt-primary10);
      &::after {
        display: none;
      }
    }
  }
}

@keyframes NewRoomAgentCall {
  0% {
    border-left: 4px solid var(--bs-gray-200);
  }
  50% {
    border-left: 4px solid var(--bs-danger);
  }
  100% {
    border-left: 4px solid var(--bs-gray-200);
  }
}

.CloseBlink {
  animation-duration: 0.5s;
  animation-name: close-tab-blinking;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes close-tab-blinking {
  from {
    background-color: #fff;
  }
  to {
    background-color: rgba(241, 8, 8, 0.39);
  }
}
