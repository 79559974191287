.validationflow .react-flow__handle {
  width: 8px;
  height: 8px;
  background: #999;
}

.validationflow .react-flow__handle-connecting {
  background: #ff6060;
}

.validationflow .react-flow__handle-valid {
  background: #55dd99;
}
