
.RealContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 2rem;
  >:nth-child(3) {
    grid-column: 3;
    grid-row: 1 / span 3;
  }
  >:nth-child(4) {
    grid-column: 4;
    grid-row: 1 / span 3;
  }
}

.TodayContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 2rem;
}

.Status{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .Name{
    font-size: 1rem;
    color: var(--bs-secondary);
  }
  .Count{
    font-size: 2.5rem;
    color: var(--bs-dark);
    font-weight: bold;
  }
}

.Panel {
  border: 1px solid #E0E0E0;
  border-radius: 0.625rem;
  padding: 1rem 2rem;
}