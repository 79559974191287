.AgentStatus{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  margin: 1rem;

  .TotalInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .Avatar{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 5rem;
      height: 5rem;
      background-color: var(--bs-primary);
      color: #fff;
      svg{
        width: 70%;
        height: 70%;
      }
    }
    .Name{
      font-size: 1rem;
      color: var(--bs-secondary);
    }
    .Count{
      margin-top: 1rem;
      font-size: 3rem;
      color: var(--bs-dark);
      font-weight: bold;
      line-height: 100%;
    }
  }
  .Bar{
    width: 100%;
  }
  .Info{
    display: flex;
    justify-content: space-around;
    width: 100%;
    .Status{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .Name{
        font-size: 1rem;
        color: var(--bs-secondary);
      }
      .Count{
        font-size: 2rem;
        color: var(--bs-dark);
        font-weight: bold;
      }
    }
  }

}

.CountStatus{
  display: flex;
  border-radius: .5rem;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  color: #fff;

  .Icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    background-color: rgba(255,255,255,.5);
  }
  &.PrimaryColor{
    background-color: var(--bs-primary);
    .Icon{
      color:var(--bs-primary);
    }
    &.Reverse{
      .Icon{
        background-color: var(--bs-primary);
      }
    }
  }
  &.WarningColor{
    background-color: var(--bs-warning);
    .Icon{
      color:var(--bs-warning);
    }
    &.Reverse{
      .Icon{
        background-color: var(--bs-warning);
      }
    }
  }
  &.SuccessColor{
    background-color: var(--bs-success);
    .Icon{
      color:var(--bs-success);
    }
    &.Reverse{
      .Icon{
        background-color: var(--bs-success);
      }
    }
  }
  &.DangerColor{
    background-color: var(--bs-danger);
    .Icon{
      color:var(--bs-danger);
    }
    &.Reverse{
      .Icon{
        background-color: var(--bs-danger);
      }
    }
  }
  &.InfoColor{
    background-color: var(--bs-info);
    .Icon{
      color:var(--bs-info);
    }
    &.Reverse{
      .Icon{
        background-color: var(--bs-info);
      }
    }
  }
  &.Reverse{
    background-color: #fff;
    color:#000;
    .Icon{
      color:#fff;
    }
  }
}
