.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  dl {
    width: 100%;
    margin: 0;
    dt {
      margin-bottom: 6px;
      display: flex;
      font-size: 15px;
      &[data-change='true']::after {
        content: '';
        background-color: #0078fe;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        display: inline-block;
      }
    }
    dd {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      p {
        width: 250px;
        margin: 0;
        border: 1px solid #eee;
        background-color: #f5f5f5;
        padding: 8px;
        border-radius: 5px;
        font-size: 13px;
        min-height: 38px;
        &.PrevText {
          color: #999;
        }
        &.AddText {
          width: 100%;
        }
      }
    }
  }
}
