.Card{
  &.Grabbing{
    pointer-events: auto !important;
    cursor:grabbing !important;
    border: 1px solid var(--bs-primary);
    background-color: var(--bs-white);
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
    .Handle{
      cursor:grabbing !important;
    }
  }
  display: flex;
  flex-direction: row;
  margin-bottom: .5rem;
  border-right: .5rem;
  align-items: center;
  border-bottom: 1px solid #E8E8E8;
  .Handle{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .5rem .5rem;
    cursor: grab;
  }
  .Content{
    flex: 1;
    padding: .5rem;
    padding-left: 0;
    .Header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .Delete{
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #001E3C;
        &.Disabled{
          cursor: not-allowed;
          color: #CED4DA;
        }
      }
    }
    .TextArea{
      resize: none;
    }
  }
}