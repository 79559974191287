@import '../../scss/breakpoints';

.ChartBigCount{
  .Count{
    color: var(--bs-dark);
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: -0.16rem;
    line-height: 100%;
  }
  .Description{
    color: var(--bs-secondary);
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: -.0125rem;
  }
}

.Border {
  border: 1px solid #e8e8e8;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: #FFFFFF;
}