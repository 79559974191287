.ContentAddButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .75rem 1rem;

  &:hover {
    background-color: var(--bs-gray-200);
  }

  .Button{
    width: 1.5rem;
    height: 1.5rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--bs-primary);

    &:hover {
      color: var(--bs-white);
    }
  }
}
