.DataGridWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 65%;
  flex-grow: 1;
  :global {
    .active-tr {
      background-color: #d6e9f4;
      &:hover {
        background-color: #b4d7eb;
      }
      td {
        box-shadow: none !important;
        background-color: transparent !important;
      }
    }
  }
}
.FormWrapper {
  height: 100%;
  padding-bottom: 20px;
  width: 35%;
  max-width: 500px;
}

.SearchForm,
.SearchContent {
  height: 100%;
}
.SearchContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  button {
    height: 100%;

    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
