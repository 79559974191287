.Button{
  .Line{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  %Button{
    cursor: pointer;
    background-color: #EEF5F9;
    position: absolute;
    padding: 0 .7rem;
  }
  .Delete{
    @extend %Button;
    color: var(--bs-warning);
  }
  .Add{
    @extend %Button;
    color: var(--bs-primary);
  }
}